<template>
  <section class="section">
    <b-tabs type="is-toggle-rounded" position="is-right" size="is-small">
      <b-tab-item label="Total">
        <line-chart :data="getTotalPosts()" thousands="," />
        <div class="columns">
          <div class="column has-text-right">
            <span class="subtitle has-text-centered has-text-grey">Total posts</span>
          </div>
        </div>

        <div class="columns">
          <div class="column is-8 is-offset-2">
            <b-table
              :data="getTotalPostTable()"
              :striped="true"
              :bordered="true"
              :mobile-cards="true"
              default-sort="date"
              :paginated="true"
              per-page="15"
              pagination-position="bottom"
            >
              <template slot-scope="props">
                <b-table-column field="date" label="Date" sortable :custom-sort="sortByDate">
                  {{ props.row.date.toLocaleDateString() }}
                </b-table-column>

                <b-table-column label="Total posts" sortable>
                  <span
                    :class="[
                      { 'has-text-danger': props.row.evolution <= 0 },
                      { 'has-text-success': props.row.evolution > 0 },
                    ]"
                    >{{ props.row.evolution | formatNumber }}</span
                  >
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  // data() {
  //   return {
  //     loading: false,
  //   };
  // },
  computed: {
    ...mapGetters("chart", ["challenge"]),
  },
  methods: {
    getTotalPosts() {
      const data = {};
      this.challenge.analytics.forEach(element => {
        if (element.posts !== undefined) {
          const d = new Date(element.scan_date).toString();
          data[d] = element.posts;
        }
      });
      return data;
    },
    getPostsBar() {
      const data = [];
      this.challenge.analytics.forEach(element => {
        if (element.posts !== undefined) {
          const d = new Date(element.scan_date);
          data.push([d, element.posts]);
        }
      });
      const d = data.sort((a, b) => a[0] - b[0]);
      d.forEach(element => {
        element[0] = element[0].toLocaleDateString();
      });
      return d;
    },
    getTotalPostTable() {
      const data = [];
      console.log("d");
      this.challenge.analytics.forEach(element => {
        if (element.posts !== undefined) {
          console.log(element);
          const d = new Date(element.scan_date);
          const i = { date: d, evolution: element.posts };
          data.push(i);
        }
      });
      return data;
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    },
    sortByUpload(a, b, isAsc) {
      if (isAsc) {
        return new Date(b.create_time).getTime() - new Date(a.create_time).getTime();
      }
      return new Date(a.create_time).getTime() - new Date(b.create_time).getTime();
    },
  },
};
// total_posts
</script>

<style></style>
