<template>
  <section class="box">
    <div class="section is-title has-text-centered">
      <span class="is-title is-size-4"> Metrics breakdown </span>
    </div>
    <b-tabs position="is-centered" class="has-background-white" type="is-toggle-rounded">
      <b-tab-item label="Posts">
        <section class="section">
          <div class="box">
            <PostGraph />
          </div>
        </section>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import PostGraph from "@/components/viral/PostGraph";

export default {
  components: { PostGraph },
};
// total_posts
</script>

<style></style>
