<template>
  <section>
    <div class="columns is-centered">
      <div class="column is-1 text-semi-bold has-text-centered">
        Filter by
      </div>
    </div>
    <div class="columns  is-centered">
      <div class="column is-1">
        <b-field label="Period">
          <b-select v-model="periodicity" placeholder="Select a growth metrics">
            <option value="daily">
              Daily
            </option>
            <option value="weekly">
              Weekly
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-1">
        <b-field v-if="periodicity === 'daily'" label="Kind">
          <b-select v-model="kind" placeholder="Select a growth metrics">
            <option value="">
              Overall
            </option>
            <option value="posts">
              Growth
            </option>
          </b-select>
        </b-field>
        <b-field v-else label="Kind">
          <b-select v-model="kind" placeholder="Growth">
            <option value="posts">
              Growth
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Date">
          <b-datepicker
            v-model="select_date"
            placeholder="Click to select..."
            icon="calendar-today"
            :min-date="minDate"
            :max-date="maxDate"
          />
        </b-field>
      </div>
      <div class="column is-2">
        <b-field v-if="kind === 'posts'" label="Minimum Posts">
          <b-input v-model="min" placeholder="Minimum Posts" type="number" min="0" />
        </b-field>
      </div>
      <div class="column is-2">
        <b-field v-if="kind === 'posts'" label="Maximum Posts">
          <b-input v-model="max" placeholder="Maximum Posts" type="number" min="0" />
        </b-field>
      </div>
      <div class="column is-1" />
    </div>
    <div class="columns is-centered">
      <div class="column is-1 text-semi-bold has-text-centered">
        <b-button type="is-primary" icon-right="refresh" @click="loadAsyncData()">
          Get tracks
        </b-button>
      </div>
    </div>
    <b-table
      :data="data"
      :loading="loading"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :current-page.sync="currentPage"
      @page-change="onPageChange"
    >
      <template slot-scope="props">
        <b-table-column field="ranking" label="Rank" width="40" numeric>
          {{ props.row.ranking }}
        </b-table-column>
        <b-table-column field="name" label="Song Name">
          <router-link :to="getRouterLink(props.row.song_id)">
            {{ props.row.name }}
          </router-link>
        </b-table-column>

        <b-table-column field="author_name" label="Author Name">
          {{ props.row.author_name }}
        </b-table-column>
        <b-table-column label="Posts" field="posts">
          {{ props.row.posts | formatNumber }}
        </b-table-column>
        <b-table-column field="growth" label="% variation" numeric :visible="kind !== ''">
          {{ ((props.row.growth - 1) * 100).toFixed(3) }}
        </b-table-column>
        <b-table-column width="40">
          <a :href="getLink(props.row.url)" target="_blank">
            <b-button type="is-link" outlined icon-right="link" />
          </a>
        </b-table-column>
        <b-table-column width="40">
          <b-button
            type="is-primary"
            outlined
            icon-right="chart-line"
            tag="router-link"
            :to="getRouterLink(props.row.song_id)"
          />
        </b-table-column>
      </template>
    </b-table>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    const today = new Date();
    const maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    return {
      data: [],
      total: 0,
      loading: false,
      page: 1,
      perPage: 20,
      maxDate,
      select_date: maxDate,
      currentPage: 1,
      kind: "",
      min: 10000,
      max: 0,
      periodicity: "daily",
    };
  },
  computed: {
    minDate() {
      const today = new Date();

      if (this.user.plan === "starter" || this.user.plan === "starter-yearly") {
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
      }
      if (this.user.plan === "growth" || this.user.plan === "growth-yearly") {
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 8);
      }
      if (this.user.plan === "pro" || this.user.plan === "pro-yearly") {
        return new Date(2020, 0, 1);
      }
      return today;
    },
    ...mapGetters("user", ["user"]),
  },
  watch: {
    periodicity() {
      if (this.periodicity === "weekly") {
        this.kind = "posts";
      }
    },
  },
  mounted() {
    // this.loadAsyncData();
  },
  methods: {
    /*
     * Load async data
     */
    loadAsyncData() {
      const params = [
        `date=${moment(this.select_date).format("YYYY-MM-DD")}`,
        `limit=${this.perPage}`,
        `offset=${(this.page - 1) * this.perPage}`,
        `kind=${this.kind}`,
        `minPosts=${this.min}`,
        `maxPosts=${this.max}`,
      ].join("&");
      console.log(this.select_date);
      this.loading = true;
      let endpoint = "chart";
      if (this.periodicity === "weekly") {
        endpoint = endpoint + "Weekly";
      }
      axios
        .get(`${process.env.VUE_APP_CHART_SERVICE}/api/song/${endpoint}?${params}`)
        .then(({ data }) => {
          // api.themoviedb.org manage max 1000 pages
          this.data = [];
          this.total = data.song_count;
          data.songs.forEach(item => {
            this.data.push(item);
          });
          this.loading = false;
        })
        .catch(error => {
          this.data = [];
          this.total = 0;
          this.loading = false;
          throw error;
        });
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      if (this.user.plan === "pro") {
        this.page = page;
        this.loadAsyncData();
        return;
      }
      this.currentPage = 1;
      this.$buefy.toast.open("You should update your account to pro to fully enjoy the charts.");
    },
    getLink(id) {
      return `https://www.tiktok.com/music/${id}`;
    },
    getRouterLink(id) {
      return `/charts/${id}`;
    },
    /*
     * Type style in relation to the value
     */
  },
};
</script>

<style>
table.table td .image {
  height: 64px;
  width: 64px;
}
</style>
