<template>
  <section class="section">
    <p class="has-text-centered title is-3">
      Music Charts
    </p>
    <div v-if="user.plan === 'free'">
      <div class="columns is-centered">
        <div class="column is-half has-text-centered">
          <img class="svg-custom" src="../assets/upgrade.svg" />
        </div>
      </div>
      <div class="columns container is-centered">
        <div class="column is-half has-text-centered subtitle">
          Want to see our viral charts ?
          <p>
            <router-link to="setting">
              Upgrade
            </router-link>
            your account.
          </p>
        </div>
      </div>
    </div>
    <Trending v-else />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Trending from "@/components/viral/Table";

export default {
  components: { Trending },
  computed: {
    ...mapGetters("user", ["user"]),
  },
  mounted() {
    this.$amplitude.logEvent("Viral charts dashboard");
  },
};
</script>

<style></style>
