<template>
  <section>
    <div class="section ">
      <p class="subtitle is-size-2 has-text-centered has-text-weight-semibold has-text-black-ter">
        Explore 115736 challenges !
      </p>
    </div>
    <div class="columns is-centered">
      <div class="column is-half text-semi-bold has-text-centered">
        <b-field label="Search for a Hashtag">
          <b-autocomplete
            v-model="hashtag"
            rounded
            :data="suggestions"
            placeholder="e.g. fyp"
            field="Hashtag"
            :loading="isFetching"
            @typing="getAsyncData"
            @select="option => (selected = option)"
          >
            <template #empty>
              No results found
            </template>
          </b-autocomplete>
        </b-field>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-half text-bold has-text-centered">
        Or explore our chart
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-half text-semi-bold has-text-centered">
        <b-table
          :data="data"
          :loading="loading"
          paginated
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          backend-pagination
          :total="total"
          :per-page="perPage"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          :current-page.sync="currentPage"
          @page-change="onPageChange"
          @sort="onSort"
        >
          <template slot-scope="props">
            <b-table-column field="ranking" label="Rank" width="40" numeric>
              {{ props.row.rank }}
            </b-table-column>
            <b-table-column field="name" label="Challenge Name">
              <router-link :to="'/challenge/' + props.row.challenge_name">
                {{ props.row.challenge_name }}
              </router-link>
            </b-table-column>
            <b-table-column label="Posts" field="posts" numeric sortable>
              {{ props.row.posts | formatNumber }}
            </b-table-column>
            <b-table-column label="Views" field="views" numeric sortable>
              {{ props.row.views | formatNumber }}
            </b-table-column>
            <b-table-column width="40">
              <a :href="getLink(props.row.challenge_name)" target="_blank">
                <b-button type="is-link" outlined icon-right="link" />
              </a>
            </b-table-column>
            <!-- <b-table-column width="40">
            <b-button
              type="is-primary"
              outlined
              icon-right="chart-line"
              tag="router-link"
              :to="getRouterLink(props.row.song_id)"
            />
          </b-table-column> -->
          </template>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable no-invalid-this */
import axios from "axios";
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
  data() {
    return {
      data: [],
      total: 0,
      loading: false,
      hashtag: "",
      suggestions: [],
      page: 1,
      perPage: 20,
      currentPage: 1,
      sortField: "posts",
      sortOrder: "desc",
      defaultSortOrder: "desc",
      isFetching: false,
      selected: null,
    };
  },

  computed: {
    minDate() {
      const today = new Date();

      if (this.user.plan === "starter" || this.user.plan === "starter-yearly") {
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
      }
      if (this.user.plan === "growth" || this.user.plan === "growth-yearly") {
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 8);
      }
      if (this.user.plan === "pro" || this.user.plan === "pro-yearly") {
        return new Date(2020, 0, 1);
      }
      return today;
    },
    ...mapGetters("user", ["user"]),
  },
  watch: {
    selected() {
      console.log("ret");
      this.$router.push("/challenge/" + this.selected);
    },
  },
  mounted() {
    this.loadAsyncData();
    // this.loadAsyncData();
  },
  methods: {
    /*
     * Load async data
     */
    async loadAsyncData() {
      const params = [
        `limit=${this.perPage}`,
        `offset=${(this.page - 1) * this.perPage}`,
        `sort_by=${this.sortField}`,
        `order_by=${this.sortOrder}`,
      ].join("&");
      this.loading = true;
      try {
        const result = await axios.get(
          `${process.env.VUE_APP_HASHTAG_SERVICE}/hashtags/chart?${params}`
        );

        this.data = [];
        this.total = result.data.total;
        result.data.data.forEach(item => {
          this.data.push(item);
        });
        this.loading = false;
      } catch (error) {
        this.data = [];
        this.total = 0;
        this.loading = false;
      }
    },

    getAsyncData: debounce(async function(hashtag) {
      // String update
      if (this.hashtag !== hashtag) {
        this.hashtag = hashtag;
        this.suggestions = [];
      }
      // String cleared
      if (!this.hashtag.length) {
        this.suggestions = [];
        return;
      }
      // Reached last page

      this.isFetching = true;
      const result = await axios.get(
        `https://trendinghashtag-db-q3d6cebooq-ew.a.run.app/hashtags/search?hashtag=${hashtag}`
      );
      console.log(result);
      if (result.data.length > 0) {
        this.suggestions = [];
        result.data.forEach(item => this.suggestions.push(item.challenge_name));
      }
      this.isFetching = false;
    }, 500),

    /*
     * Handle page-change event
     */
    onPageChange(page) {
      if (this.user.plan === "pro") {
        this.page = page;
        this.loadAsyncData();
        return;
      }
      this.currentPage = 1;
      this.$buefy.toast.open("You should update your account to pro to fully enjoy the charts.");
    },
    async onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      await this.loadAsyncData();
    },
    getLink(id) {
      return `https://www.tiktok.com/tag/${id}`;
    },
    // getRouterLink(id) {
    //   return `/charts/${id}`;
    // },
    /*
     * Type style in relation to the value
     */
  },
};
</script>

<style>
table.table td .image {
  height: 64px;
  width: 64px;
}
</style>
