<template>
  <div>
    <section v-if="!loading" class="section">
      <div class="columns is-centered">
        <div class="column has-text-centered">
          <p class="title has-margin-top-10">
            {{ challenge.info.challenge_name }}
          </p>
          <p class="is-size-4">
            <a :href="`https://www.tiktok.com/tag/${challenge.info.challenge_name}`" target="_blank"
              >View on TikTok</a
            >
          </p>
          <p class="is-size-6">
            Last updated {{ new Date(challenge.info.last_stats_updated).toLocaleDateString() }}
          </p>
        </div>
      </div>

      <div class="columns is-centered">
        <div class="column is-10">
          <ChartGraph />
        </div>
      </div>
    </section>
    <b-loading :active.sync="loading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ChartGraph from "./ChartGraph";

export default {
  components: { ChartGraph },

  computed: {
    ...mapGetters("chart", ["challenge", "loading"]),
  },
  mounted() {
    this.$amplitude.logEvent("display hashtag chart Result");
    this.$store.dispatch("chart/getChallenge", { id: this.$route.params.id });
  },
  methods: {},
};
</script>

<style></style>
