<template>
  <section class="section">
    <b-tabs type="is-toggle-rounded" position="is-right" size="is-small">
      <b-tab-item label="Total">
        <line-chart :data="getTotalPosts()" :download="true" thousands="," />

        <div class="section columns is-centered">
          <div class="column has-text-centered">
            <span class="subtitle has-text-centered"> Top Videos</span>
          </div>
        </div>
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <b-table
              :data="videos"
              :mobile-cards="true"
              default-sort="date"
              per-page="15"
              pagination-position="bottom"
            >
              <template slot-scope="props">
                <b-table-column label="Video">
                  <a :href="getVideoLink(props.row.unique_id, props.row.video_id)" target="_blank">
                    <figure class="image is-9by16">
                      <img
                        class="has-ratio"
                        width="264"
                        height="469"
                        :src="
                          `https://www.tiktok.com/api/img/?itemId=${props.row.video_id}&location=0&aid=1988`
                        "
                      />
                    </figure>
                  </a>
                </b-table-column>
                <b-table-column field="unique_id" label="Creator" sortable>
                  {{ props.row.unique_id }}
                </b-table-column>
                <b-table-column
                  field="date"
                  label="Upload date"
                  sortable
                  :custom-sort="sortByUpload"
                >
                  {{ new Date(props.row.create_time).toLocaleDateString() }}
                </b-table-column>

                <b-table-column field="digg_count" label="Likes" sortable numeric>
                  {{ props.row.digg_count | formatNumber }}
                </b-table-column>
                <b-table-column field="comment_count" label="Comments" sortable numeric>
                  {{ props.row.comment_count | formatNumber }}
                </b-table-column>
                <b-table-column field="share_count" label="Shares" sortable numeric>
                  {{ props.row.share_count | formatNumber }}
                </b-table-column>
                <b-table-column field="video_link" label="Tiktok" sortable numeric>
                  <a :href="getVideoLink(props.row.unique_id, props.row.video_id)" target="_blank">
                    View on TikTok
                  </a>
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-right">
            <span class="subtitle has-text-centered has-text-grey">Total posts</span>
          </div>
          <div class="column has-text-right">
            <b-button v-if="!loading" type="is-primary" @click="exportToCSV()">
              Export to csv
            </b-button>
            <b-button v-else type="is-primary" loading>
              Export to csv
            </b-button>
          </div>
        </div>

        <div class="columns">
          <div class="column is-8 is-offset-2">
            <b-table
              :data="getTotalPostTable()"
              :striped="true"
              :bordered="true"
              :mobile-cards="true"
              default-sort="date"
              :paginated="true"
              per-page="15"
              pagination-position="bottom"
            >
              <template slot-scope="props">
                <b-table-column field="date" label="Date" sortable :custom-sort="sortByDate">
                  {{ props.row.date.toLocaleDateString() }}
                </b-table-column>

                <b-table-column label="Total posts" sortable>
                  <span
                    :class="[
                      { 'has-text-danger': props.row.evolution <= 0 },
                      { 'has-text-success': props.row.evolution > 0 },
                    ]"
                    >{{ props.row.evolution | formatNumber }}</span
                  >
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("chart", ["sound", "videos"]),
  },
  methods: {
    getTotalPosts() {
      const data = {};
      this.sound.song_stats.forEach(element => {
        if (element.posts !== undefined) {
          const d = new Date(element.model.CreatedAt).toString();
          data[d] = element.posts;
        }
      });
      return data;
    },
    getPostsBar() {
      const data = [];
      this.sound.song_stats.forEach(element => {
        if (element.posts !== undefined) {
          const d = new Date(element.model.CreatedAt);
          data.push([d, element.posts]);
        }
      });
      const d = data.sort((a, b) => a[0] - b[0]);
      d.forEach(element => {
        element[0] = element[0].toLocaleDateString();
      });
      return d;
    },
    getTotalPostTable() {
      const data = [];
      this.sound.song_stats.forEach(element => {
        if (element.posts !== undefined) {
          const d = new Date(element.model.CreatedAt);
          const i = { date: d, evolution: element.posts };
          data.push(i);
        }
      });
      return data;
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    },
    sortByUpload(a, b, isAsc) {
      if (isAsc) {
        return new Date(b.create_time).getTime() - new Date(a.create_time).getTime();
      }
      return new Date(a.create_time).getTime() - new Date(b.create_time).getTime();
    },
    exportToCSV() {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(this.getTable()[0]).join(";"),
        ...this.getTable().map(item => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },

    getVideoLink(uniqueId, videoId) {
      return `https://www.tiktok.com/@${uniqueId}/video/${videoId}`;
    },
  },
};
// total_posts
</script>

<style></style>
