<template>
  <div>
    <section v-if="!loading" class="section">
      <div class="columns is-centered">
        <div class="column has-text-centered">
          <p class="title has-margin-top-10">
            {{ sound.song_name }}
          </p>
          <p class="subtitle">by {{ sound.Author.name }}</p>
          <p class="">
            <a :href="`https://www.tiktok.com/music/${sound.url}`" target="_blank"
              >Listen on TikTok</a
            >
          </p>
        </div>
      </div>

      <div class="columns is-centered">
        <div class="column is-10">
          <ChartGraph />
        </div>
      </div>
    </section>
    <b-loading :active.sync="loading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ChartGraph from "@/components/viral/ChartGraph";

const prettyMilliseconds = require("pretty-ms");

export default {
  components: { ChartGraph },

  computed: {
    getLastModification() {
      const startDate = new Date();
      const last = new Date();
      return prettyMilliseconds(startDate - last);
    },
    ...mapGetters("chart", ["sound", "loading"]),
  },
  mounted() {
    this.$amplitude.logEvent("display chart Result");
    this.$store.dispatch("chart/getSound", { id: this.$route.params.id });
  },
  methods: {},
};
</script>

<style></style>
