<template>
  <section class="section">
    <div v-if="user.plan === 'free'">
      <div class="columns is-centered">
        <div class="column is-half has-text-centered">
          <img class="svg-custom" src="../assets/upgrade.svg" />
        </div>
      </div>
      <div class="columns container is-centered">
        <div class="column is-half has-text-centered subtitle">
          Want to see our hashtags charts ?
          <p>
            <router-link to="setting">
              Upgrade
            </router-link>
            your account.
          </p>
        </div>
      </div>
    </div>
    <ChallengeTable v-else />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ChallengeTable from "@/components/hashtagcharts/Table";

export default {
  components: { ChallengeTable },
  computed: {
    ...mapGetters("user", ["user"]),
  },
  mounted() {
    this.$amplitude.logEvent("hashtag charts dashboard");
  },
};
</script>

<style></style>
